import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { calcularMinutosTranscurridos } from '@/utils/fechas'

const url = `${config.URL_BASE}tickets`

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateTicket(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      // eslint-disable-next-line no-param-reassign
      if (!body.trackingEstados) body.trackingEstados = []
      if (body.estado !== 'PENDIENTE' && body.trackingEstados.filter(e => e.estado === body.estado).length === 0) {
        body.trackingEstados.push({
          estado: body.estado,
          horaFecha: new Date(),
        })
      }
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    if (body.estado === 'ASIGNADO') {
      // eslint-disable-next-line no-param-reassign
      body.trackingEstados = [{
        estado: 'PENDIENTE',
        horaFecha: new Date(),
      },
      {
        estado: 'VERIFICADO',
        horaFecha: new Date(),
      },
      {
        estado: 'ASIGNADO',
        horaFecha: new Date(),
      }]
    } else {
      // eslint-disable-next-line no-param-reassign
      body.trackingEstados = [{
        estado: 'PENDIENTE',
        horaFecha: new Date(),
      }]
    }
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Ticket', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findTickets(filter, getPunto = false) {
  try {
    const request = new RequestAdmin()
    const urrl = getPunto ? `${url}/punto?filter=${JSON.stringify(filter)}` : `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}

export async function countTickets(where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener Tickets', err)
    return null
  }
}

export async function getTicket(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Ticket', err)
    return null
  }
}

export function decimals(x) {
  return Number.parseFloat(x).toFixed(2)
}

const fuentes = [
  {
    id: 1,
    nombre: 'VECINO',
  },
  {
    id: 2,
    nombre: 'MUNICIPALIDAD',
  },
  {
    id: 3,
    nombre: 'APVN',
  },
  {
    id: 4,
    nombre: 'OTROS',
  },
]

export function getUtilsTickets(tipo, usuario) {
  if (tipo === 1) {
    const modifiedFuentes = [...fuentes]
    if (usuario.company.name === 'APMIX') {
      modifiedFuentes[2].nombre = 'APMIX'
    }
    return modifiedFuentes
  }
  return []
}

export function getHoras(row, type) {
  if (row.imagenes && row.imagenes.length > 0) {
    const now = new Date()
    if (!row.trackingEstados) return ''
    if (row.trackingEstados.length === 0) return ''
    let fechaHoraEstado = null
    if (type === 'V') {
      const estados = row.trackingEstados.filter(e => e.estado === 'VERIFICADO')
      fechaHoraEstado = estados.length > 0 ? new Date(estados[0].horaFecha) : now
      const minutos = calcularMinutosTranscurridos(row.imagenes[0].fechaHora, fechaHoraEstado)
      return decimals(Math.abs(minutos / 60))
    } if (type === 'S') {
      const asignados = row.trackingEstados.filter(e => e.estado === 'ASIGNADO')
      fechaHoraEstado = asignados.length > 0 ? new Date(asignados[0].horaFecha) : now
      const minutos = calcularMinutosTranscurridos(row.imagenes[0].fechaHora, fechaHoraEstado)
      return decimals(Math.abs(minutos / 60))
    }
    const inicios = row.trackingEstados.filter(e => e.estado === 'VERIFICADO')
    const inicio = inicios.length > 0 ? new Date(inicios[0].horaFecha) : new Date(row.imagenes[0].fechaHora)
    const estados = row.trackingEstados.filter(e => e.estado === 'FINALIZADA')
    fechaHoraEstado = estados.length > 0 ? new Date(estados[0].horaFecha) : now
    const minutos = calcularMinutosTranscurridos(inicio, fechaHoraEstado)
    return decimals(Math.abs(minutos / 60))
  }
  return ''
}

export function getStatusClassHex(row, type) {
  const horas = getHoras(row, type)
  if (horas < 6) return '#4df348'
  if (horas >= 6 && horas < 10) return '#666666'
  if (horas >= 10 && horas < 12) return '#ff230e'
  return '#ff0000'
}

export function getColorFuente(fuente) {
  if (fuente === 'MUNICIPALIDAD') return 'light-danger'
  if (fuente === 'VECINO') return 'light-warning'
  if (fuente === 'APVN') return 'light-secondary'

  return 'light-success'
}
